import React, {useEffect, useState} from "react";
import {Question, responseAnalysis, ViewType} from "../../../../modals/quizquestionData/Modal";
import {useStudentApiClient} from "../../apiClients/StudentApiClient";
import {useParams, useNavigate} from "react-router-dom";
import Questions from "../../components/quiz/quizQuestions/Questions";
import QuizStepper, {QuestionData} from "../../components/quiz/quizStepper/QuizStepper";
import ReviewStepper from "../../components/quiz/quizStepper/ReviewStepper";
import './TestReview.scss'
import {Modal} from "react-bootstrap";
import {Comprehension} from "../../../../dto/ComprehensionTest";
import {PassageModal} from "../../components/quiz/CompQuiz/PassageModal";
import QuizStatusIndicator from "../../components/quiz/quizIndicator/QuizStatusIndicator";

const TestReview = () => {
    const [resultAnalysisData, setResultAnalysisData] = useState<responseAnalysis[]>([])
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
    const [questionData, setQuestionData] = useState(new Map<number, QuestionData>());
    const studentApiClient = useStudentApiClient();
    const params = useParams();
    const navigate = useNavigate();
    const [showMobileSummary, setShowMobileSummary] = useState<boolean>(false);
    const [isExplanationVisible, setIsExplanationVisible] = useState(false);
    const [showComprehensionModal, setShowComprehensionModal] = useState(false);
    const [currentComprehensionText, setCurrentComprehensionText] = useState('');
    const [currentComprehensionIndex, setCurrentComprehensionIndex] = useState(0);
    const [comprehensionData, setComprehensionData] = useState<Comprehension[]>([]);
    const [currentComprehensionImage, setCurrentComprehensionImage] = useState<string>('');

    useEffect(() => {
        if (params.Test === "Test" || params.Test === "Practise") {
            getStudentTestResult();
        } else if (params.Test === "Assignment") {
            getAssignmentResponse()
        } else {
            if (params.Test === "TEST") {
                getTestResponse();
            } else if (params.Test === 'ASSIGNMENT') {
                getAssignmentResponse()
            } else if (params.Test === 'PRACTICE') {
                getTestResponse()
            }
        }
    }, [params]);

    const getTestResponse = () => {
        studentApiClient.getTestResult(parseInt(params?.resultId ?? '')).then((res: any) => {
            setResultAnalysisData(res.data.analysis);
            const _questionData = new Map<number, QuestionData>();
            res.data?.analysis?.forEach((item: responseAnalysis) => {
                _questionData?.set(item.question.id, {question: item.question, status: item.status});
            });
            setQuestionData(_questionData);
            setCurrentQuestionIndex(0);
            setComprehensionData(res.data.test.comprehensions);
            setCurrentComprehensionIndex(0)
            if (res.data.test.comprehensions.length > 0) {
                setShowComprehensionModal(true);
                setCurrentComprehensionText(res.data.test.comprehensions[currentComprehensionIndex].text);
                setCurrentComprehensionImage(res.data.test.comprehensions[currentComprehensionIndex].imageUrl);

            }
        }).catch((e) => {
            console.log("#24", e);
        });
    };

    const getStudentTestResult = () => {
        studentApiClient.getStudentTestResult(parseInt(params?.resultId ?? '')).then((res: any) => {
            setResultAnalysisData(res.data.analysis);
            const _questionData = new Map<number, QuestionData>();
            res.data.analysis.forEach((item: responseAnalysis) => {
                _questionData.set(item.question.id, {question: item.question, status: item.status});
            });
            setQuestionData(_questionData);
            setCurrentQuestionIndex(0);
            setComprehensionData(res.data.test.comprehensions);
            setCurrentComprehensionIndex(0)
            if (res.data.test.comprehensions.length > 0) {
                setShowComprehensionModal(true);
                setCurrentComprehensionText(res.data.test.comprehensions[currentComprehensionIndex].text);
                setCurrentComprehensionImage(res.data.test.comprehensions[currentComprehensionIndex].imageUrl);
            }
        }).catch((e) => {
            console.log("#24", e);
        });
    };

    const getAssignmentResponse = () => {
        studentApiClient.getAssignmentResult(parseInt(params?.resultId ?? '')).then((res: any) => {
            setResultAnalysisData(res.data.analysis);
            const _questionData = new Map<number, QuestionData>();
            res.data.analysis.forEach((item: responseAnalysis) => {
                _questionData.set(item.question.id, {question: item.question, status: item.status});
            });
            setQuestionData(_questionData);
            setCurrentQuestionIndex(0);
            setComprehensionData(res.data.assignment.comprehensions);
            setCurrentComprehensionIndex(0)
            if (res.data.assignment.comprehensions.length > 0) {
                setShowComprehensionModal(true);
                setCurrentComprehensionText(res.data.assignment.comprehensions[currentComprehensionIndex].text);
                setCurrentComprehensionImage(res.data.assignment.comprehensions[currentComprehensionIndex].imageUrl);
            }
        }).catch((e) => {
            console.log("#24", e);
        });
    };
    const handlePreviousQuestion = () => {
        const comprehensionIdMap = new Map();
        comprehensionData?.forEach((comprehension, index) => {
            comprehension?.questions?.forEach((question) => {
                comprehensionIdMap.set(question?.id, comprehension?.id);
            });
        });
        setIsExplanationVisible(false);
        const previousQuestion = currentQuestionIndex - 1;
        if (previousQuestion >= 0) {
            setCurrentQuestionIndex(previousQuestion);
            const currentQuestionId = resultAnalysisData[currentQuestionIndex]?.question?.id;
            const previousQuestionId = resultAnalysisData[previousQuestion]?.question?.id;
            const currentComprehensionId = comprehensionIdMap.get(currentQuestionId);
            const previousComprehensionId = comprehensionIdMap.get(previousQuestionId);
            if (currentComprehensionId !== previousComprehensionId || previousQuestion === 0) {
                const prevIndex = currentComprehensionIndex - 1;
                setCurrentComprehensionIndex(Math.max(prevIndex, 0));
                const previousComprehensionIndex = Math.max(prevIndex, 0);
                setCurrentComprehensionText(comprehensionData[previousComprehensionIndex].text ?? '');
                setCurrentComprehensionImage(comprehensionData[previousComprehensionIndex].imageUrl ?? '');
                setShowComprehensionModal(true);
            }
        }
    };


    const handleNextQuestion = () => {
        const comprehensionIdMap = new Map();
        comprehensionData?.forEach((comprehension, index) => {
            comprehension?.questions?.forEach((question) => {
                comprehensionIdMap?.set(question?.id, comprehension?.id);
            });
        });
        const nextQuestion = currentQuestionIndex + 1;
        setIsExplanationVisible(false);
        if (nextQuestion < resultAnalysisData.length) {
            setCurrentQuestionIndex(nextQuestion);
            const currentQuestionId = resultAnalysisData[currentQuestionIndex]?.question?.id;
            const nextQuestionId = resultAnalysisData[nextQuestion]?.question?.id;
            const currentComprehensionId = comprehensionIdMap.get(currentQuestionId);
            const nextComprehensionId = comprehensionIdMap.get(nextQuestionId);
            if (currentComprehensionId !== nextComprehensionId && nextComprehensionId !== undefined) {
                const nextIndex = currentComprehensionIndex + 1;
                setCurrentComprehensionIndex(nextIndex);
                const nextComprehensionIndex = Math.min(nextIndex, comprehensionData.length - 1);
                setCurrentComprehensionText(comprehensionData[nextComprehensionIndex].text ?? '');
                setCurrentComprehensionImage(comprehensionData[nextComprehensionIndex].imageUrl ?? '');
                setShowComprehensionModal(true);
            }
        }
    };


    const handleStepperNavigator = (index: number) => {
        setCurrentQuestionIndex(index);

        const comprehensionIdMap = new Map();
        comprehensionData?.forEach((comprehension, i) => {
            comprehension?.questions?.forEach((question) => {
                comprehensionIdMap?.set(question?.id, comprehension?.id);
            });
        });

        const currentQuestionId = resultAnalysisData[currentQuestionIndex]?.question?.id;
        const targetQuestionId = resultAnalysisData[index]?.question?.id;
        const currentComprehensionId = comprehensionIdMap.get(currentQuestionId);
        const targetComprehensionId = comprehensionIdMap.get(targetQuestionId);

        if (currentComprehensionId !== targetComprehensionId && targetComprehensionId !== undefined) {
            const targetComprehensionIndex = comprehensionData.findIndex(comprehension => comprehension.id === targetComprehensionId);
            setCurrentComprehensionIndex(targetComprehensionIndex);
            setCurrentComprehensionText(comprehensionData[targetComprehensionIndex].text ?? '');
            setCurrentComprehensionImage(comprehensionData[targetComprehensionIndex].imageUrl ?? '');
            // setShowComprehensionModal(true);
        }
    };

    const onSummaryClick = () => {
        setShowMobileSummary(true)
    }

    const handleBookMark = (questionId: number) => {
        setQuestionData((prevState) => {
            const _questionData = new Map(prevState);
            const qd = _questionData.get(questionId);
            if (qd) {
                if (qd.bookMark === undefined)
                    qd.bookMark = true;
                else
                    qd.bookMark = !qd.bookMark;
                _questionData.set(questionId, qd);
            }
            return _questionData;
        });
    }
    return <div className={'container mt-3'}>
        <div className={'onMobile_time mb-4'}>
            <ReviewStepper currentStep={currentQuestionIndex} questionData={Array.from(questionData.values())}
                           onClick={handleStepperNavigator}/>
        </div>
        <div className='row gx-3 justify-content-between m-sm-3 m-1 rounded-1'>
            <div className='col-md-8 bg-white rounded-1'>
                <Questions question={resultAnalysisData[currentQuestionIndex]?.question ?? ''}
                           nextQuestion={handleNextQuestion} previousQuestion={handlePreviousQuestion}
                           currentQuestionIndex={currentQuestionIndex}
                           selectedOptionId={resultAnalysisData[currentQuestionIndex]?.attemptedOptions ?? []}
                           quizView={ViewType.REVIEW} questionData={Array.from(questionData.values())}
                           exit={() => navigate(-1)}
                           answer={resultAnalysisData[currentQuestionIndex]?.attemptedAnswer ?? " "}
                           showSummary={true}
                           onSummaryClick={onSummaryClick}
                           isExplanationVisible={isExplanationVisible}
                           reviewScreen={'review'}
                           attemptedOption={resultAnalysisData[currentQuestionIndex]?.attemptedOption ?? -1}
                           currentComprehensionIndex={currentComprehensionIndex}
                           currentComprehensionImage={currentComprehensionImage}
                           currentComprehensionText={currentComprehensionText}
                />
            </div>
            <div className='Stepper col-md-4'>
                <ReviewStepper currentStep={currentQuestionIndex} questionData={Array.from(questionData.values())}
                               onClick={handleStepperNavigator}/>
            </div>
        </div>
        <Modal show={showMobileSummary} onHide={() => setShowMobileSummary(false)}
               aria-labelledby="contained-modal-title-vcenter"
               centered>
            <Modal.Header closeButton className={'border-0'}>
            </Modal.Header>
            <Modal.Body>
                <ReviewStepper currentStep={currentQuestionIndex} questionData={Array.from(questionData.values())}
                               onClick={handleStepperNavigator}/>
            </Modal.Body>
        </Modal>
        <div>
            <PassageModal show={showComprehensionModal} onHide={() => setShowComprehensionModal(false)}
                          currentComprehensionText={currentComprehensionText}
                          currentComprehensionImage={currentComprehensionImage}
            />
        </div>
    </div>

}
export default TestReview;