import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
import {LeaderBoardData} from "../../../../dto/StudentDashBoard";
import {useEffect, useState} from "react";
import {useStudentApiClient} from "../../apiClients/StudentApiClient";
import {useParams} from "react-router-dom";
import Pagination from "../../../../components/pagination/Pagination";
import {useAdminApiClient} from "../../../admin/apiClients/AdminApiClient";

interface LeaderBoardAllStudentsModalProps {
    show: boolean,
    onHide: () => void,
    courseId?: number
    courseName?:string
}

export const LeaderBoardAllStudentsModal = (props: LeaderBoardAllStudentsModalProps) => {
    const [leaderBoardData, setLeaderBoardData] = useState<LeaderBoardData[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const studentApiClient = useStudentApiClient();
    const adminApiClient = useAdminApiClient();
    const params = useParams();

    useEffect(() => {
        if (props.courseId !== undefined) {
            getLeaderBoardDataForAdmin(props.courseId)
        } else if (params && params.courseBatchId)
            getLeaderBoardDataForStudent(Number(params.courseBatchId))
    }, [params.courseBatchId, props.courseId]);

    useEffect(() => {
        console.log('leader',leaderBoardData)
    }, [leaderBoardData]);
    const getLeaderBoardDataForStudent = (courseId: number, page?: number, size?: number) => {
        studentApiClient.getLeaderBoardData(courseId, page, size).then((res) => {
            setLeaderBoardData(res.data.content)
            setPageSize(res.data.pageable.pageSize);
            setTotalPages(res.data.totalPages);
            setCurrentPage(res.data.pageable.pageNumber);
            setTotalElements(res.data.totalElements);
        }).catch((err) => {
            console.log(err)
        })
    }

    const getLeaderBoardDataForAdmin = (courseId: number, page?: number, size?: number) => {
        adminApiClient.getLeaderBoardDataByCourseId(courseId, page, size).then((res) => {
            setLeaderBoardData(res.data.content)
            setPageSize(res.data.pageable.pageSize);
            setTotalPages(res.data.totalPages);
            setCurrentPage(res.data.pageable.pageNumber);
            setTotalElements(res.data.totalElements);
        }).catch((err) => {
            console.log(err)
        })
    }
    return <Modal show={props.show} onHide={props.onHide}>
        <ModalHeader closeButton>
            <div>
                <h6>LeaderBoard</h6>
            </div>
        </ModalHeader>
        <ModalBody>
            <div>
                <h6>{props.courseName}</h6>
            </div>
            {leaderBoardData && leaderBoardData.length > 0 ?
                <table className={'table table-striped'}>
                    <thead>
                    <tr>
                        <th>Sr.No</th>
                        <th>Name</th>
                        <th>Rank</th>
                    </tr>
                    </thead>
                    <tbody>
                    {leaderBoardData?.sort((a, b) => a.rank - b.rank)?.map((res, index) => (
                        <tr key={res.id}>
                            <td>{currentPage * pageSize + index + 1}</td>
                            <td>{res.name}</td>
                            <td>{res.rank}</td>
                            {/*<td>{res.rank > leaderBoardData.length ? "--/--" : res.rank}</td>*/}
                        </tr>
                    ))}

                    </tbody>
                </table> :
                <div className='d-flex align-items-center justify-content-center m-5'>
                    <h6 className={'fw-bold'}>No Data Found!</h6>
                </div>
            }
            {totalPages > 1 && (
                props.courseId ? (
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => getLeaderBoardDataForAdmin(Number(props.courseId), page, pageSize)}
                        totalElements={totalElements}
                    />
                ) : (
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => getLeaderBoardDataForStudent(Number(params.courseBatchId), page, pageSize)}
                        totalElements={totalElements}
                    />
                )
            )}
        </ModalBody>
    </Modal>
}